@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
.tile {
    border-radius: 4px;
    transition: all 200ms;
    picture {
        transform-origin: center;
        transition: scale 200ms;

        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: top;
            transform-origin: center;
            transition: all 200ms;
        }
    }
    &:hover,
    &:active {
        picture img {
            transform: scale(1.02);
        }
    }
    svg {
        fill: inherit;
    }
    header,
    section {
        width: 100%;
    }

    header {
        border-radius: 4px 4px 0 0;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: hidden;
        @apply h-[180px] sm:h-[170px] md:h-[158px] xl:h-[200px];
    }
    section {
        border-radius: 0 0 4px 4px;
        height: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        letter-spacing: 3px;
        @include xl-up {
            font-size: $font-small;
        }
    }
}
